<template>
    <div id="multiple-access-alert" v-if="otherUsers.length > 0">
        <v-card elevation="2" outlined width="300" style="background: rgba(255, 255, 203, .7)">
            <div class="ml-2 mt-2">{{ $t('message.warning') + '!' }}</div>
            <div class="ml-2 font-sm">{{ $t('message.theseUsersOpenedSamePage') + ':'}}</div>
            <ul>
                <li v-for="user in otherUsers" class="font-sm">{{ user.user_id }}</li>
            </ul>
        </v-card>
    </div>
</template>

<script>
export default {
    name: "MultipleAccess",
    props: ['assetId','eventId', 'model'],
    data() {
        return {
            multipleAccessPolling: null,
            otherUsers: []
        }
    },
    methods: {
        multipleAccessPollData () {
            const _this = this
            this.multipleAccessPolling = setInterval(() => {
                const assetData = {
                    assetId: _this.assetId,
                    eventId: _this.eventId,
                    model: _this.model
                }
                this
                    .$store
                    .dispatch('runtime/pollMultipleAccessLog', assetData)
                    .then((otherUsers) => {
                        _this.otherUsers = otherUsers
                    });
            }, 30000)
        },
    },
    created() {
        this.otherUsers = [];
        this.multipleAccessPollData();
    },
    beforeDestroy() {
        this.otherUsers = [];
        clearInterval(this.multipleAccessPolling)
    },
    beforeUnmounted() {
        this.otherUsers = [];
        clearInterval(this.multipleAccessPolling)
    }
}
</script>
<style scoped>
#multiple-access-alert {
    position: fixed;
    bottom: 20px; right: 30px;
    z-index: 999
}
</style>